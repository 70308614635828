<template>
  <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1_4175)">
      <rect x="2.54163" y="1" width="38" height="38" rx="6" fill="#FEF2F2"/>
      <rect x="3.04163" y="1.5" width="37" height="37" rx="5.5" stroke="#DC2626"/>
      <path d="M25.5416 20H15.5416" stroke="#DC2626" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <filter id="filter0_d_1_4175" x="0.541626" y="0" width="42" height="42" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_4175"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_4175" result="shape"/>
      </filter>
    </defs>
  </svg>
</template>