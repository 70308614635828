import { mapActions } from "vuex";
import { GET_ATTENDANCE_STATISTICS, GET_ATTENDANCE_STATISTICS_TABLE } from "@/store/attendance";

export default {
  data() {
    return {
      statistics: null,
      table_statistics: null
    };
  },
  async mounted() {
    await this.getAttendanceStatistics();
    await this.getAttendanceStatisticsTable();
  },
  methods: {
    ...mapActions([GET_ATTENDANCE_STATISTICS, GET_ATTENDANCE_STATISTICS_TABLE]),
    async getAttendanceStatistics() {
      if (this.group) {
        this.statistics = await this[GET_ATTENDANCE_STATISTICS]({
          group_number: this.group,
          date_from: this.start_date,
          date_to: this.end_date,
        });
        this.chartData = this.generateChartData();
      }
    },
    async getAttendanceStatisticsTable() {
      if (this.group) {
        this.table_statistics = await this[GET_ATTENDANCE_STATISTICS_TABLE]({
          group_number: this.group,
          date_from: this.start_date,
          date_to: this.end_date,
        });
      }
    },
  },
  watch: {
    async group() {
      await this.getAttendanceStatistics();
      await this.getAttendanceStatisticsTable();
    },
  },
};
