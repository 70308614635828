<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <AttendanceModal
    :show="isShow"
    @close="isShow = false"
    :student="modalStudent"
  />
  <div>
    <p class="text-2xl text-gray-900 font-semibold pt-2 pb-4">
      {{ $t("attendance") }}
    </p>
    <div class="flex flex-col gap-4">
      <div class="flex gap-4 w-full items-end">
        <div class="rounded-md w-2/5 flex flex-col gap-2">
          <label class="block text-sm font-medium leading-5 text-gray-700"
            >Группа</label
          >
          <div class="bg-white rounded-md">
            <GroupSelector
              v-if="course_id"
              label="name"
              :course_id="course_id"
              v-model="group_id"
              :placeholder="$t('select_group')"
              class="text-sm leading-5 font-medium border-none"
              @updateGroupName="setGroupName"
              :is-attendance="true"
            />
          </div>
        </div>
        <div class="rounded-md w-2/5 flex flex-col gap-2">
          <label class="block text-sm font-medium leading-5 text-gray-700"
            >Дата</label
          >
          <div class="bg-white rounded-md">
            <CalendarSelector v-model="date" />
          </div>
        </div>
        <div class="flex items-center pb-2">
          <div :class="loading ? 'loader' : 'loader-hidden'" />
        </div>
      </div>
      <TheCard>
        <div v-if="students.length" class="flex flex-col gap-6 relative">
          <div class="flex gap-4 absolute left-0">
            <ButtonTemplate
              class-names="btn-attendance"
              @click="fillAllStudentAttendance(true)"
              ><div><AttendancePresentIcon /></div>
              <div>Все присутствовали</div></ButtonTemplate
            >
            <ButtonTemplate
              class-names="btn-attendance"
              @click="fillAllStudentAttendance(false)"
              ><div><AttendanceAbsentIcon /></div>
              <div>Все отсутствовали</div></ButtonTemplate
            >
            <ButtonTemplate
              class-names="btn-attendance"
              @click="fillAllStudentAttendance(null)"
              >
              <div><AttendanceXIcon /></div>
              <div>Убрать все отметки</div></ButtonTemplate
            >
          </div>
          <div class="flex justify-end">
            <div class="flex gap-4 ml-4">
              <ButtonTemplate
                class-names="btn-download"
                @click="downloadAttendance($event)"
                ><div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.33398 13.3327L3.33398 14.166C3.33398 15.5467 4.45327 16.666 5.83398 16.666L14.1673 16.666C15.548 16.666 16.6673 15.5467 16.6673 14.166L16.6673 13.3327M13.334 9.99935L10.0007 13.3327M10.0007 13.3327L6.66732 9.99935M10.0007 13.3327L10.0006 3.33268"
                      stroke="#4F46E5"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div>Выгрузить таблицу</div></ButtonTemplate
              >
              <ButtonTemplate
                class-names="btn-save-data"
                @click="saveAttendanceRecord($event)"
              >
                <div>
                  {{ savedText }}
                </div></ButtonTemplate
              >
            </div>
          </div>

          <div class="flex flex-col">
            <div class="table-header flex items-center">
              <div class="table-checkbox"></div>
              <div class="table-checkbox"></div>
              <div class="table-name">ИМЯ</div>
              <div class="table-contacts">КОНТАКТЫ</div>
              <div class="table-top-student"></div>
            </div>
            <div
              v-for="(student, index) in students"
              :key="student.id"
              class="flex text-gray-900"
            >
              <div class="table-checkbox flex items-center">
                #{{ index+1 }}
              </div>
              <div class="table-checkbox flex items-center">
                <AttendanceCheckbox
                  :student="student"
                  @update="fillStudentAttendance"
                />
              </div>
              <div class="table-name flex gap-4 table-name-text">
                <div>
                  <img
                    :src="student.avatar"
                    alt="Avatar"
                    class="avatar"
                    v-if="student.avatar"
                  />
                  <div v-else>
                    <AvatarPlaceholder :student="student" />
                  </div>
                </div>
                <div class="flex flex-col gap-1">
                  <div>{{ student.first_name }} {{ student.last_name }}</div>
                  <div>
                    <span class="status">Группа: {{ group_name }}</span>
                  </div>
                </div>
              </div>
              <div
                class="table-contacts table-contacts-text flex flex-col gap-2"
              >
                <div class="flex gap-4">
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.5 6.66602L9.0755 11.0497C9.63533 11.4229 10.3647 11.4229 10.9245 11.0497L17.5 6.66602M4.16667 15.8327H15.8333C16.7538 15.8327 17.5 15.0865 17.5 14.166V5.83268C17.5 4.91221 16.7538 4.16602 15.8333 4.16602H4.16667C3.24619 4.16602 2.5 4.91221 2.5 5.83268V14.166C2.5 15.0865 3.24619 15.8327 4.16667 15.8327Z"
                        stroke="#6B7280"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div>{{ student.email }}</div>
                </div>
                <div class="flex gap-4">
                  <div>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H6.89937C7.25806 2.5 7.57651 2.72953 7.68994 3.06981L8.93811 6.81434C9.06926 7.20777 8.89115 7.63776 8.52022 7.82322L6.63917 8.76375C7.55771 10.801 9.19898 12.4423 11.2363 13.3608L12.1768 11.4798C12.3622 11.1088 12.7922 10.9307 13.1857 11.0619L16.9302 12.3101C17.2705 12.4235 17.5 12.7419 17.5 13.1006V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H15C8.09644 17.5 2.5 11.9036 2.5 5V4.16667Z"
                        stroke="#6B7280"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div>{{ student.phone_number || "-" }}</div>
                </div>
              </div>
              <div class="table-top-student flex items-center justify-end">
                <ButtonTemplate
                  class-names="btn-attendance"
                  @click="chooseStudentForModal(student)"
                  >Подробнее</ButtonTemplate
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex flex-col items-center">
          {{
            this.fetched
              ? "В этой группе нет студентов."
              : "Выберите группу для отображения"
          }}
        </div>
        <div ref="sentinel" class="sentinel"></div>
      </TheCard>
    </div>
  </div>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import moment from "moment";
import GroupSelector from "@/components/Selectors/GroupSelector.vue";
import TheCard from "@/components/TheCard/TheCard.vue";
import ButtonTemplate from "@/views/courses/CourseEdit/Attendance/ButtonTemplate.vue";
import StudentsFetchMixin from "@/views/certificates/StudentsFetchMixin";
import CalendarSelector from "@/components/Selectors/CalendarSelector.vue";
import AttendanceCheckbox from "@/views/courses/CourseEdit/Attendance/AttendanceCheckbox.vue";
import AttendanceModal from "@/views/courses/CourseEdit/Attendance/AttendanceModal.vue";
import AvatarPlaceholder from "@/views/courses/CourseEdit/Attendance/AvatarPlaceholder.vue";
import AttendanceMixin from "@/views/courses/CourseEdit/Attendance/AttendanceMixin";
import AttendanceAbsentIcon from "@/assets/icons/AttendanceAbsentIcon.vue";
import AttendancePresentIcon from "@/assets/icons/AttendancePresentIcon.vue";
import AttendanceXIcon from "@/assets/icons/AttendanceXIcon";
import { GET_COURSE } from "@/store/courses";
export default {
  components: {
    AvatarPlaceholder,
    AttendanceModal,
    AttendanceCheckbox,
    CalendarSelector,
    ButtonTemplate,
    TheCard,
    GroupSelector,
    Breadcrumbs,
    AttendanceAbsentIcon,
    AttendancePresentIcon,
    AttendanceXIcon,
  },
  data: () => ({
    id: null,
    groups: [],
    course: null,
    breadcrumbs: [],
    students: [],
    group: "",
    isShow: false,
    modalStudent: {},
    group_name: "",
    canModify: true,
    date: new Date(),
    savedText: "Сохранить данные",
    loading: false,
  }),
  mixins: [StudentsFetchMixin, AttendanceMixin],
  async mounted() {
    this.id = this.$route.params.id;
    this.course = await this.$store.dispatch(GET_COURSE, this.id);
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.course.name,
        path: `/courses/${this.id}/edit`,
      },
      {
        name: this.$t("attendance"),
        path: this.$route.path,
      },
    ];
  },
  methods: {
    chooseStudentForModal(value) {
      this.isShow = true;
      this.modalStudent = value;
      this.getStudentAttendanceRecords(value);
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    fillStudentAttendance(id, value) {
      this.students = this.students.map((st) =>
        st.id === id ? { ...st, attended: value } : st
      );
    },
    fillAllStudentAttendance(value) {
      this.students = this.students.map((st) => ({ ...st, attended: value }));
    },
    setGroupName(name) {
      this.group_name = name;
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
    // canModify() {
    //   const today = moment().startOf("day");
    //   const data = moment(this.date).isSame(today);
    //   console.log(data, "f");
    //   return data;
    // },
  },
  watch: {
    // async group_id() {
    //   if (this.date) {
    //     await this.getAttendanceRecords();
    //   }
    // },
    async date() {
      if (this.group_id) {
        await this.getAttendanceRecords();
      }
    },
    async students_updated() {
      if (this.group_id && this.date && this.students_updated) {
        await this.getAttendanceRecords();
      }
    },
  },
  async created() {
    this.course_id = this.$route.params.id;
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.course.name,
        path: `/courses/${this.id}/edit`,
      },
      {
        name: this.$t("attendance"),
        path: this.$route.path,
      },
    ];
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #f5f5f5;
}

th,
td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.status {
  display: block;
  color: #065f46;
  font-size: 0.8rem;
  border-radius: 10px;
  padding: 2px 10px;
  background: #d1fae5;
}
.table-checkbox {
  width: 6%;
  padding: 16px 24px;
}
.table-name {
  width: 34%;
  padding: 16px 24px;
}
.table-contacts {
  width: 47%;
  padding: 16px 24px;
}

.table-header {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #6b7280;
  background: #f9fafb;
}

.table-name-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #111827;
}
.table-contacts-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.table-top-student {
  width: 13%;
  padding: 16px 24px;
}
.loader-hidden {
  display: none;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #4f46e5;
  border-radius: 70%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
