const baseUrl = "/api/v1/course_group_attendance";
const secondUrl = "/api/v2/courses";
export default ($axios) => ({
    get_course_group_attendance(params) {
        return $axios.get(`${baseUrl}/group/${params.group_number}/statistics/?date_from=${params.date_from}&date_to=${params.date_to}`);
    },
    get_course_group_attendance_table(params) {
        return $axios.get(`${secondUrl}/course_group/${params.group_number}/attendance/statistics/table/?date_from=${params.date_from}&date_to=${params.date_to}`);
    }
});
