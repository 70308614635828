<template>
  <div
    class="flex border-[1px] p-6 text-gray-500 rounded-xl gap-3 box-border"
    :id="'user_submission' + id"
  >
    <UserAvatar :url="avatar" class="mr-3 flex-shrink-0" />
    <div class="flex flex-col w-full">
      <div class="flex justify-between w-full">
        <div class="text-sm leading-5 font-medium text-gray-900">
          {{ full_name }}
        </div>
        <div class="text-sm leading-5 font-normal">{{ formattedDate }}</div>
      </div>
      <span class="text-sm leading-5 max-w-[100%] font-semibold break-word">
        {{ $t("course_name") }}: {{ course_name }}
      </span>
      <span class="text-sm leading-5 font-normal max-w-[100%] break-word">
        {{ $t("resource_name") }}: {{ resource_name }}
      </span>
<!--      <div-->
<!--        v-html="question_text"-->
<!--        class="text-sm leading-5 font-normal max-w-[100%] break-word"-->
<!--      ></div>-->
<!--      <slot name="extra-content"></slot>-->
    </div>
  </div>
</template>
<script>
import UserAvatar from "@/components/UserAvatar";
import moment from "moment";
export default {
  components: { UserAvatar },
  props: {
    avatar: {
      type: String,
    },
    full_name: {
      type: String,
    },
    course_name: {
      type: String,
    },
    resource_name: {
      type: String,
    },
    question_text: {
      type: String,
    },
    submitted_at: {
      type: String,
    },
    id: {
      type: String,
      default: "",
    },
  },
  computed: {
    formattedDate() {
      return moment(this.submitted_at).locale("ru").format("DD MMMM YYYY");
    },
  },
};
</script>
